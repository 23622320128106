import { defineStore } from 'pinia'

export interface Notification {
  id?: number
  type: 'info' | 'error' | 'warning'
  message: string
  title: string
  read?: boolean
  hidden?: boolean
  isExternal?: boolean
}

interface NotificationsState {
  notifications: Notification[],
  isFetching: boolean
}

interface NotificationResponse {
  id: string
  attributes: {
    label: string,
    content?: string,
    isReaded: boolean,
    user: {
      data: [
        {
          id: string
        }
      ]
    }
  }
}

interface UpdatingNotification {
  isReaded: boolean
}

export const useNotificationsStore = defineStore('notifications', {
  state: (): NotificationsState => ({
    notifications: [] as Notification[],
    isFetching: false
  }),
  actions: {
    async fetchNotifications() {
      const userStore = useUserStore()

      const { fetch } = useGraphQLQuery(gql`
        query($userId: ID!) {
          notifications (
            filters: { user: { id: { eq: $userId } } }
            pagination: {}
            sort: []
          ) {
            data {
              id
              attributes {
                label
                content
                isReaded
                user {
                  data{
                    id
                  }
                }
              }
            }
          }
        }`, { userId: userStore.id })

        const data = await fetch()

        if (data && data.notifications) {
          this.notifications = data.notifications.data.map((notification: NotificationResponse) => {
            return {
              id: parseInt(notification.id),
              type: 'info',
              message: notification.attributes.content,
              title: notification.attributes.label,
              read: notification.attributes.isReaded,
              isExternal: true
            }
          })
          this.isFetching = false
      }
    },
    async updateNotification(id: number, data?: UpdatingNotification) {
      const mutation = gql`
        mutation ($id: ID!, $data: NotificationInput!) {
          updateNotification(id: $id, data: $data) {
            data{
              id
              attributes{
                isReaded
              }
            }
          }
        }
      `
      const variables = { id: id, data: data }

      const { fetch } = useGraphQLQuery(mutation, variables)

      return fetch()
    },
    addNotification(notification: Notification): void {
      const uniqueId = Date.now();
      const notificationWithId = { ...notification, id: uniqueId };

      this.notifications.push(notificationWithId)

      notificationWithId.timer = setTimeout(() => {
        if (notification.type !== 'info') {
          this.removeNotification(notificationWithId)
        } else {
          this.markAsRead(notificationWithId)
        }
      }, 5000)
    },
    removeNotification(notification: Notification): void {
      const index = this.notifications.indexOf(notification)
      if (index !== -1) {
        this.notifications.splice(index, 1)
      }
    },
    markAsRead(notification: Notification): void {
      const index = this.notifications.indexOf(notification)
      if (index !== -1) {
        const updatedNotification = { ...notification, read: true, hidden: true }
        this.notifications.splice(index, 1, updatedNotification)
      }

      if (notification.isExternal) {
        this.updateNotification(notification.id, { isReaded: true })
      }
    },
    pauseTimer(notification: Notification): void {
      const index = this.notifications.indexOf(notification)
      if (index !== -1) {
        clearTimeout(this.notifications[index].timer)
      }
    },
    resumeTimer(notification: Notification): void {
      const index = this.notifications.indexOf(notification)
      if (index !== -1) {
        this.notifications[index].timer = setTimeout(() => {
          if (notification.type !== 'info') {
            this.removeNotification(notification)
          } else {
            this.markAsRead(notification)
          }
        }, 5000)
      }
    },
  },
})
